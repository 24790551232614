import React from "react"

const Home: React.FC = () => {
  return (
    <div className="content-container">
      
    </div>
  )
}

export default Home
