export const navLeft = [
  {
    text: "Home",
    url: "/",
  },
  {
    text: "Championships",
    url: "/championships",
  },
]
